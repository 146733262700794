import { Link } from '@remix-run/react'

export function BaseHeader() {
  return (
    <nav className="bg-kalirblue-dark w-full fixed z-10 shadow">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <img
                  className="h-7 w-auto mr-4"
                  src="/kalir-logo-full.svg"
                  alt="Kalir"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
